import {Page} from './Pages/PageOne/PageOne';
import {GlobalStyles} from './Components/Style/GlobalStyle';
function App() {
  return (
    <>
      <GlobalStyles />
      <Page />
    </>
  );
}

export default App;
